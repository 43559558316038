import { Container, FlexContainer } from "src/design-system/Container";
import { ThemedProps } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { inputTokens, spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";

export const ResponsiveViewContainer = styled(FlexContainer)`
  & > div:first-child {
    flex: 1 1 100%;
    max-width: 275px;
    margin-right: 1rem;
  }

  @media all and (max-width: 768px) {
    flex-wrap: wrap;

    & > div:first-child {
      margin: 2rem 0;
      max-width: 100%;
    }
  }
`;

export const StickyFiltersContainer = styled(Container)`
  top: 1rem;
  width: 100%;
  position: sticky;
  align-self: flex-start;
  background-color: ${(props: ThemedProps) => props.theme.menu.background};
  z-index: 1;
`;

export const FiltersWrapper = styled.div`
  padding: 12px;

  & > * {
    margin-bottom: 12px;
  }
`;

export const ResetRangeButton = styled.a`
  cursor: pointer;
  font-size: 0.825rem;
  background: transparent;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const DashboardContainer = styled(FlexContainer)`
  cursor: pointer;
`;

export const DashboardNumber = styled.div<{ color?: string }>`
  font-size: 50px;
  font-weight: bold;
  ${({ color }) => (color ? `color: ${color};` : "")}
`;

export const DashboardTitle = styled.div`
  font-weight: bold;
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: ${inputTokens.inputWidth};
  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: ${inputTokens.inputTextAreaWidth};
  }
  max-width: 100%;
`;

export const ReadOnlyCommentContainer = styled.div`
  width: ${inputTokens.inputWidth};
  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: ${inputTokens.inputTextAreaWidth};
  }
  white-space: pre-wrap;
  font-family: inherit;
  font-size: 0.9em;
  line-height: 1.2;
`;

export const NotificationWrapper = styled(FlexContainer)`
  gap: ${spacer(1)};
`;
