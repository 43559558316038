import { IJWTUserInfo, ITechnicalAuditCase } from "online-services-types";
import React from "react";
import { AttachmentUploader } from "src/components/AttachmentUploader";
import { IResponsiveTableColumn } from "src/components/ResponsiveTable/interfaces";
import { AttachmentList } from "src/components/SupportList/AttachmentList";
import { themes } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { AttachmentSource } from "src/models/attachments";
import { formatDate } from "src/util/formatters";
import { translateString } from "src/util/localization";
import { ThemeProvider } from "styled-components";
import { TechnicalAuditFilters, TechnicalAuditQueryParams, TechnicalAuditSortOption } from "./tac.types";
import { TechnicalAuditControls } from "./tac.components";
import { Link } from "react-router-dom";
import { getPathWithParams, getRoutes } from "src/routes";
import { Container } from "src/design-system/Container";

export const TAC_STATUSES = ["New", "Opened", "Planned", "Done", "Reopened", "Closed"];
export const TAC_PRIORITIES = ["High", "Medium", "Low"];

export const DEFAULT_QUERY_LIMIT = 10;
export const DEFAULT_QUERY_OFFSET = 0;
export const DEFAULT_SORT_BY = "createdDate";
export const DEFAULT_SORT_ORDER = "DESC";

export function getQueryParamsFromFilters(filters: TechnicalAuditFilters): TechnicalAuditQueryParams {
  const params: TechnicalAuditQueryParams = {};
  if (filters.id) params.id = filters.id;
  if (filters.installation && filters.installation.length > 0) params.installation = filters.installation.join(",");
  if (filters.status && filters.status.length > 0) params.status = filters.status.join(",");
  if (filters.priority && filters.priority.length > 0) params.priority = filters.priority.join(",");
  if (filters.createdDateFrom) params.createdDateFrom = filters.createdDateFrom;
  if (filters.createdDateTo) params.createdDateTo = filters.createdDateTo;
  if (filters.dueDateFrom) params.dueDateFrom = filters.dueDateFrom;
  if (filters.dueDateTo) params.dueDateTo = filters.dueDateTo;
  if (filters.overdueOnly) params.overdueOnly = "true";
  if (filters.limit && filters.limit !== DEFAULT_QUERY_LIMIT) params.limit = filters.limit.toString();
  if (filters.offset && filters.offset !== DEFAULT_QUERY_OFFSET) params.offset = filters.offset.toString();
  if (filters.sortBy && filters.sortBy !== DEFAULT_SORT_BY) params.sortBy = filters.sortBy;
  if (filters.sortOrder && filters.sortOrder !== DEFAULT_SORT_ORDER) params.sortOrder = filters.sortOrder;
  return params;
}

export const getSortOptions = (): TechnicalAuditSortOption[] => [
  {
    label: translateString("sorting.newestFirst"),
    sortBy: "createdDate",
    sortOrder: "DESC",
  },
  {
    label: translateString("sorting.oldestFirst"),
    sortBy: "createdDate",
    sortOrder: "ASC",
  },
  {
    label: translateString("sorting.modified"),
    sortBy: "lastModifiedDate",
    sortOrder: "DESC",
  },
  {
    label: translateString("sorting.dueDate"),
    sortBy: "dueDate",
    sortOrder: "DESC",
  },
  {
    label: translateString("sorting.priority"),
    sortBy: "priority",
    sortOrder: "ASC",
  },
  {
    label: translateString("sorting.installationNameAZ"),
    sortBy: "installationName",
    sortOrder: "ASC",
  },
  {
    label: translateString("sorting.subjectAZ"),
    sortBy: "subject",
    sortOrder: "ASC",
  },
];

export const getColumns = (
  filters: TechnicalAuditFilters,
  user: IJWTUserInfo,
  isAttachmentsBusy: boolean,
  handleAttachmentsBusy: (busy: boolean) => void
): IResponsiveTableColumn<ITechnicalAuditCase>[] => [
  {
    priority: 10,
    plannedWidth: 150,
    key: "subject",
    alwaysShown: true,
    label: translateString("request.subject"),
  },
  {
    priority: 9,
    plannedWidth: 150,
    key: "installationName",
    label: translateString("installation"),
  },
  {
    priority: 8,
    plannedWidth: 150,
    key: "priority",
    label: translateString("contracts.tac.priority"),
  },
  {
    priority: 7,
    plannedWidth: 150,
    key: "status",
    label: translateString("status"),
  },
  {
    priority: 6,
    plannedWidth: 150,
    key: "requestId",
    label: translateString("request.requestId"),
  },
  {
    priority: 5,
    plannedWidth: 150,
    key: "createdDate",
    label: translateString("request.creationDate"),
    valueRenderer: ({ row: { createdDate } }) => (createdDate ? formatDate(createdDate) : ""),
  },
  {
    priority: 4,
    plannedWidth: 150,
    key: "dueDate",
    label: translateString("spareParts.invoice.dueDate"),
    valueRenderer: ({ row: { dueDate } }) => (dueDate ? formatDate(dueDate) : ""),
  },
  {
    priority: 3,
    plannedWidth: 150,
    key: "lastModifiedDate",
    label: translateString("request.lastModified"),
    valueRenderer: ({ row: { lastModifiedDate } }) => (lastModifiedDate ? formatDate(lastModifiedDate) : ""),
  },
  {
    priority: 2,
    plannedWidth: 150,
    key: "description",
    alwaysHidden: true,
    label: translateString("request.description"),
  },
  {
    priority: 1,
    plannedWidth: 150,
    key: "equipmentNames",
    alwaysHidden: true,
    label: translateString("documents.appliesTo"),
    valueRenderer: ({ row: { installationName, equipmentNames } }) => (
      <div>
        <div style={{ fontWeight: "bold" }}>{installationName}</div>
        {(equipmentNames || []).map((productName, key) => (
          <div key={key}>{productName}</div>
        ))}
      </div>
    ),
  },
  {
    priority: 1,
    plannedWidth: 150,
    key: "serviceWorkReportId",
    alwaysHidden: true,
    hideEmptyValues: true,
    label: translateString("contracts.tac.serviceWorkReport"),
    valueRenderer: ({ row: { serviceWorkReportId } }) => (
      <Link
        to={getPathWithParams(getRoutes().Documents, { documentId: serviceWorkReportId })}
        style={{ color: colors.primary.blue }}
      >
        {serviceWorkReportId}
      </Link>
    ),
  },
  {
    priority: 1,
    plannedWidth: 150,
    key: "requestOwner",
    alwaysHidden: true,
    hideEmptyValues: true,
    label: translateString("contracts.tac.wartsilaContact"),
  },
  {
    priority: 1,
    plannedWidth: 150,
    key: "primaryContact",
    alwaysHidden: true,
    label: translateString("contracts.tac.assignees"),
    isEnabled: (row) => Boolean(row.primaryContact || row.secondaryContact),
    valueRenderer: ({ row: { primaryContact, secondaryContact } }) => (
      <div>
        {primaryContact && <div>{primaryContact}</div>}
        {secondaryContact && <div>{secondaryContact}</div>}
      </div>
    ),
  },
  {
    priority: 1,
    plannedWidth: 150,
    key: "attachments",
    alwaysHidden: true,
    label: translateString("request.attachments"),
    valueRenderer: ({ row }) => (
      <ThemeProvider theme={themes.light}>
        <Container $padding={[0, 2, 0, 0]}>
          <AttachmentList
            attachmentSource={AttachmentSource.TechnicalAudit}
            isUploaderBusy={isAttachmentsBusy}
            sourceId={row.id}
            user={user}
            showTitle={true}
          />
          {row.editAllowed && (
            <AttachmentUploader
              iconColor={colors.primary.black}
              relatedId={row.id}
              files={[]}
              onFilesChanged={() => false}
              onBusy={handleAttachmentsBusy}
            />
          )}
        </Container>
      </ThemeProvider>
    ),
  },
  {
    priority: 1,
    plannedWidth: 150,
    key: "customerComment",
    alwaysHidden: true,
    label: "",
    valueRenderer: ({ row }) => (
      <ThemeProvider theme={themes.light}>
        <Container $padding={[0, 2, 0, 0]}>
          <TechnicalAuditControls tac={row} filters={filters} />
        </Container>
      </ThemeProvider>
    ),
  },
];

export function getNewStatus(tac: ITechnicalAuditCase, planned: boolean, done: boolean) {
  let newStatus = tac.status;
  if (planned && !done) newStatus = "Planned";
  else if (!planned && done) newStatus = "Done";
  else if (!planned && !done && tac.status !== "Closed" && tac.status !== "Reopened") newStatus = "Opened";
  return newStatus;
}
