import { IServiceAnnouncement } from "online-services-types";
import * as React from "react";
import colors from "src/design-system/Tokens/colors";
import striptags from "striptags";
import styled from "styled-components";

interface IServiceUnavailableNotificationProps {
  serviceAnnouncements: IServiceAnnouncement[];
}

const OrangeLine = styled.div`
  height: fit-content;
  padding: 5px;
  background-color: ${colors.primary.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const ServiceUnavailableNotification = ({ serviceAnnouncements }: IServiceUnavailableNotificationProps) => {
  return (
    <>
      {serviceAnnouncements.map((announcement, key) => (
        <OrangeLine key={key}>{striptags(announcement.message)}</OrangeLine>
      ))}
    </>
  );
};
