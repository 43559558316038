import React, { useState } from "react";
import { Container, FlexContainer } from "src/design-system/Container";
import { Select } from "src/design-system/Select";
import { DropdownStyle, ISelectObject } from "src/design-system/Select/Select";
import { H2 } from "src/design-system/Tokens/typography";
import { translateString } from "src/util/localization";
import { TechnicalAuditDashboard, TechnicalAuditFiltersComponent, TechnicalAuditListComponent } from "./tac.components";
import { ResponsiveViewContainer, StickyFiltersContainer } from "./tac.styles";
import { TechnicalAuditFilters, TechnicalAuditSortOption } from "./tac.types";
import { DEFAULT_SORT_BY, DEFAULT_SORT_ORDER, getSortOptions } from "./tac.utils";

const DEFAULT_FILTERS: TechnicalAuditFilters = {
  installation: [],
  priority: [],
  status: [],
  createdDateFrom: "",
  createdDateTo: "",
  dueDateFrom: "",
  dueDateTo: "",
  overdueOnly: false,
  sortBy: DEFAULT_SORT_BY,
  sortOrder: DEFAULT_SORT_ORDER,
};

export const TechnicalAuditView = () => {
  const [filters, setFilters] = useState<TechnicalAuditFilters>(DEFAULT_FILTERS);
  const [isFiltersTouched, setIsFiltersTouched] = useState<boolean>(false);

  const setSingleFilter = <T extends keyof TechnicalAuditFilters>(key: T, value: TechnicalAuditFilters[T]) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    if (!isFiltersTouched) setIsFiltersTouched(true);
  };

  const sortOptions = getSortOptions();

  return (
    <Container $fullwidth>
      <TechnicalAuditDashboard setSingleFilter={setSingleFilter} />

      <ResponsiveViewContainer $fullwidth $marginTop={8}>
        <FlexContainer>
          <StickyFiltersContainer>
            <TechnicalAuditFiltersComponent filters={filters} setSingleFilter={setSingleFilter} />
          </StickyFiltersContainer>
        </FlexContainer>

        <Container $fullwidth>
          <FlexContainer $spaceBetween $marginBottom={1}>
            <H2>{translateString("contracts.tac.technicalAuditCases")}</H2>
            <FlexContainer>
              <Select
                placeholder={translateString("select.sortPlaceholder")}
                options={sortOptions}
                value={sortOptions.find((so) => so.sortBy === filters.sortBy && so.sortOrder === filters.sortOrder)}
                onChange={(option: TechnicalAuditSortOption) => {
                  setSingleFilter("sortBy", option.sortBy);
                  setSingleFilter("sortOrder", option.sortOrder);
                }}
                dropdownStyle={DropdownStyle.Toolbar}
                getOptionLabel={(option: ISelectObject<TechnicalAuditSortOption>) => option.item.label}
              />
            </FlexContainer>
          </FlexContainer>
          <TechnicalAuditListComponent filters={filters} showPreselected={!isFiltersTouched} />
        </Container>
      </ResponsiveViewContainer>
    </Container>
  );
};
