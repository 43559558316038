import React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const MarineAuditIcon = (props: IIconBase) => (
  <IconBase {...props} viewBox="0 0 48 48">
    <title>Technical Audit</title>
    <polygon points="20 10.46 29.17 15.75 29.17 13.79 29.17 13.77 29.17 11.81 24.97 9.39 24.97 5.87 22.97 5.87 22.97 3.87 16.94 3.87 16.94 5.87 14.93 5.87 14.93 9.41 10.76 11.81 10.76 13.77 10.76 13.79 10.76 15.75 19.93 10.46 20 10.46" />
    <path d="M38.3,39.72l-.36.35-.7-.7a8.43,8.43,0,0,0,1.81-5.28,8.6,8.6,0,0,0-5.5-8L34,25.06v-4L20,13,6,21.06v4l8,20H26l1.24-3.1a8.58,8.58,0,0,0,3.3.68h0a8.38,8.38,0,0,0,5.24-1.82l.7.71-.35.35L39.36,45l2.12-2.12ZM20,41V17.6l10,5.77v.92l-.53,1.3A8.47,8.47,0,0,0,23.91,39.4L23.26,41Zm15.2-2.31a6.44,6.44,0,0,1-4.58,1.89h0a6.59,6.59,0,0,1-4.69-11.19,6.49,6.49,0,0,1,4.58-1.88,6.59,6.59,0,0,1,6.6,6.57A6.45,6.45,0,0,1,35.17,38.71Z" />
  </IconBase>
);
