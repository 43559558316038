import { Button, ButtonVariant } from "@wartsila/ui-kit";
import { Field, Form, Formik } from "formik";
import moment from "moment/moment";
import { ICustomerGroupInstallation, ITechnicalAuditCase } from "online-services-types";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { NoRequestsFoundIndicator } from "src/components/CommerceList/styles";
import { LoadingSpinner, spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { ResponsiveTable } from "src/components/ResponsiveTable";
import { Checkbox, FormCheckbox } from "src/design-system/Checkbox";
import { Container, FlexContainer } from "src/design-system/Container";
import { StyledFormField, StyledInputLabel } from "src/design-system/Form";
import { RangePicker } from "src/design-system/RangePicker";
import { DropdownStyle, ISelectObject, Select } from "src/design-system/Select/Select";
import { TextArea } from "src/design-system/TextArea";
import colors from "src/design-system/Tokens/colors";
import { IconSize } from "src/design-system/Tokens/icons";
import { Disclaimer } from "src/design-system/Tokens/typography";
import { WarningIcon } from "src/icons";
import { dateFormat } from "src/util/formatters";
import { translateString } from "src/util/localization";
import { validateForm, validators } from "src/util/validators";
import {
  usePatchTechnicalAuditCase,
  usePreselectedTechnicalAuditCase,
  useTechnicalAuditCases,
  useTechnicalAuditDashboard,
  useTechnicalAuditFiltersDispatchProps,
  useTechnicalAuditFiltersStateProps,
  useTechnicalAuditListStateProps,
} from "./tac.hooks";
import {
  CommentContainer,
  DashboardContainer,
  DashboardNumber,
  DashboardTitle,
  FiltersWrapper,
  NotificationWrapper,
  ReadOnlyCommentContainer,
  ResetRangeButton,
} from "./tac.styles";
import {
  DashboardCardProps,
  TechnicalAuditControlsForm,
  TechnicalAuditDashboardProps,
  TechnicalAuditFilters,
  TechnicalAuditFiltersProps,
  TechnicalAuditListProps,
} from "./tac.types";
import { getColumns, getNewStatus, TAC_PRIORITIES, TAC_STATUSES } from "./tac.utils";

export const TechnicalAuditFiltersComponent = ({ filters, setSingleFilter }: TechnicalAuditFiltersProps) => {
  const { installations, isReady } = useTechnicalAuditFiltersStateProps();
  const { getInstallations } = useTechnicalAuditFiltersDispatchProps();

  useEffect(() => {
    if (!installations.length && isReady) {
      getInstallations();
    }
  }, []);

  return (
    <FiltersWrapper>
      <Select
        isMulti
        isClearable
        isSearchable
        isLoading={!isReady}
        options={installations}
        value={installations.filter((inst) => filters.installation?.includes(inst.salesforceId))}
        label={translateString("installation")}
        dropdownStyle={DropdownStyle.FullWidth}
        getOptionLabel={(option: ISelectObject<ICustomerGroupInstallation>) => option.item.name}
        getOptionValue={(option: ISelectObject<ICustomerGroupInstallation>) => option.item.salesforceId}
        onChange={(value: ICustomerGroupInstallation[] = []) =>
          setSingleFilter(
            "installation",
            value.map((val) => val.salesforceId)
          )
        }
      />
      <Select
        isMulti
        isClearable
        isSearchable
        options={TAC_PRIORITIES}
        value={filters.priority}
        label={translateString("contracts.tac.priority")}
        dropdownStyle={DropdownStyle.FullWidth}
        getOptionLabel={(option: ISelectObject<string>) => option.item}
        getOptionValue={(option: ISelectObject<string>) => option.item}
        onChange={(value: string[] = []) => setSingleFilter("priority", value)}
      />
      <Select
        isMulti
        isClearable
        isSearchable
        options={TAC_STATUSES}
        value={filters.status}
        label={translateString("status")}
        dropdownStyle={DropdownStyle.FullWidth}
        getOptionLabel={(option: ISelectObject<string>) => option.item}
        getOptionValue={(option: ISelectObject<string>) => option.item}
        onChange={(value: string[] = []) => setSingleFilter("status", value)}
      />
      <StyledFormField>
        <StyledInputLabel>{translateString("request.creationDate")}</StyledInputLabel>
        <RangePicker
          startDate={filters.createdDateFrom ? moment(filters.createdDateFrom) : undefined}
          endDate={filters.createdDateTo ? moment(filters.createdDateTo) : undefined}
          onChange={(range) => {
            const [startDate, endDate] = range;
            setSingleFilter("createdDateFrom", startDate?.format(dateFormat));
            setSingleFilter("createdDateTo", endDate?.format(dateFormat));
            return range;
          }}
          placeholder={translateString("documents.select")}
          maxDate={moment()}
        />
        <ResetRangeButton
          type="button"
          onClick={() => {
            setSingleFilter("createdDateFrom", "");
            setSingleFilter("createdDateTo", "");
          }}
        >
          {translateString("documents.resetRange")}
        </ResetRangeButton>
      </StyledFormField>
      <StyledFormField>
        <StyledInputLabel>{translateString("spareParts.invoice.dueDate")}</StyledInputLabel>
        <RangePicker
          startDate={filters.dueDateFrom ? moment(filters.dueDateFrom) : undefined}
          endDate={filters.dueDateTo ? moment(filters.dueDateTo) : undefined}
          onChange={(range) => {
            const [startDate, endDate] = range;
            setSingleFilter("dueDateFrom", startDate?.format(dateFormat));
            setSingleFilter("dueDateTo", endDate?.format(dateFormat));
            return range;
          }}
          placeholder={translateString("documents.select")}
          maxDate={moment()}
        />
        <ResetRangeButton
          type="button"
          onClick={() => {
            setSingleFilter("dueDateFrom", "");
            setSingleFilter("dueDateTo", "");
          }}
        >
          {translateString("documents.resetRange")}
        </ResetRangeButton>
      </StyledFormField>
      <Checkbox
        label={translateString("contracts.tac.showOnlyOverdue")}
        checked={Boolean(filters.overdueOnly)}
        onChange={(event) => setSingleFilter("overdueOnly", event.target.checked)}
      />
    </FiltersWrapper>
  );
};

export const TechnicalAuditListComponent = ({ filters, showPreselected }: TechnicalAuditListProps) => {
  const { tacId } = useParams<{ tacId?: string }>();
  const [isAttachmentsBusy, setIsAttachmentsBusy] = useState<boolean>(false);
  const { ref, inView } = useInView({ initialInView: true });
  const { userInfo } = useTechnicalAuditListStateProps();
  const { data: preselectedTAC } = usePreselectedTechnicalAuditCase(tacId, Boolean(tacId && showPreselected));
  const {
    data: tacs,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useTechnicalAuditCases(filters);
  const { mutate } = usePatchTechnicalAuditCase({ filters });

  useEffect(() => {
    if (!isFetching) refetch();
  }, []);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const onOpen = (tac: ITechnicalAuditCase) => {
    if (tac.editAllowed && tac.status === "New") {
      mutate({ updatedTAC: { ...tac, status: "Opened" } });
    }
  };

  const getExtraRows = (tac: ITechnicalAuditCase) => {
    if (!tac.overdue) return null;
    return (
      <NotificationWrapper $padding={[2, 3]} $centered>
        <WarningIcon size={IconSize.Small} />
        {translateString("spareParts.invoice.overdue")}
      </NotificationWrapper>
    );
  };

  const allTACs = showPreselected
    ? [...(preselectedTAC || []), ...(tacs?.pages?.flat() || []).filter((tac) => tac.id !== tacId)]
    : tacs?.pages?.flat();

  if (!allTACs?.length && !isFetching && !isFetchingNextPage) {
    return (
      <NoRequestsFoundIndicator>
        <LocalizedString id="contracts.tac.noCasesFound" />
      </NoRequestsFoundIndicator>
    );
  }

  return (
    <>
      <Container $marginBottom={2}>
        <ResponsiveTable<ITechnicalAuditCase>
          rows={allTACs?.length ? allTACs : undefined}
          columns={getColumns(filters, userInfo, isAttachmentsBusy, setIsAttachmentsBusy)}
          onRowExpand={onOpen}
          isItemOpen={(tac) => tac.id === tacId && showPreselected}
          ensureVisibleId={showPreselected ? tacId : undefined}
          displayedItemId={showPreselected ? tacId : undefined}
          scrollToOpenItem={showPreselected}
          getHasAlert={(tac) => tac.overdue}
          getAlertColor={() => colors.notification.error}
          rowExtension={getExtraRows}
        />
      </Container>

      {hasNextPage ? (
        <Button ref={ref} variant={ButtonVariant.Warning} loading={isFetchingNextPage} onClick={() => fetchNextPage()}>
          {translateString("main.loadMore")}
        </Button>
      ) : null}
    </>
  );
};

const DashboardCard = ({ title, amount = 0, color, onClick }: DashboardCardProps) => {
  return (
    <DashboardContainer $column $centered onClick={onClick}>
      <DashboardNumber color={color}>{amount}</DashboardNumber>
      <DashboardTitle>{title}</DashboardTitle>
    </DashboardContainer>
  );
};

export const TechnicalAuditDashboard = ({ setSingleFilter }: TechnicalAuditDashboardProps) => {
  const { data, isFetching } = useTechnicalAuditDashboard();

  const setStatus = (status: string) => {
    setSingleFilter("status", [status]);
    setSingleFilter("overdueOnly", false);
  };

  const setOverdueOnly = () => {
    setSingleFilter("status", []);
    setSingleFilter("overdueOnly", true);
  };

  return (
    <FlexContainer $wrap $spaceEvenly>
      {!isFetching ? (
        <>
          <DashboardCard title={translateString("new")} amount={data?.newCases} onClick={() => setStatus("New")} />
          <DashboardCard
            title={translateString("managerDashboard.documents.opened")}
            amount={data?.openedCases}
            onClick={() => setStatus("Opened")}
          />
          <DashboardCard
            title={translateString("contracts.tac.planned")}
            amount={data?.plannedCases}
            onClick={() => setStatus("Planned")}
          />
          <DashboardCard
            title={translateString("spareParts.invoice.overdue")}
            amount={data?.overdueCases}
            color={(data?.overdueCases ?? 0) > 0 ? colors.notification.error : undefined}
            onClick={() => setOverdueOnly()}
          />
        </>
      ) : (
        <LoadingSpinner disableText={true} size={spinnerSize.md} />
      )}
    </FlexContainer>
  );
};

export const TechnicalAuditControls = ({
  tac,
  filters,
}: {
  tac: ITechnicalAuditCase;
  filters: TechnicalAuditFilters;
}) => {
  const { mutate, isLoading } = usePatchTechnicalAuditCase({ filters });

  const onSaveComment = (data: TechnicalAuditControlsForm) => {
    const newStatus = getNewStatus(tac, data.planned, data.done);
    if (tac.editAllowed && tac.status !== newStatus) {
      mutate({
        updatedTAC: {
          ...tac,
          customerComment: data.customerComment,
          status: newStatus,
        },
      });
    }
  };

  return (
    <Container $marginTop={tac.editAllowed ? 3 : 0}>
      {tac.editAllowed ? (
        <Formik<TechnicalAuditControlsForm>
          initialValues={{
            customerComment: tac.customerComment || "",
            planned: tac.status === "Planned",
            done: tac.status === "Done",
          }}
          validate={(values) =>
            validateForm(values, {
              customerComment: [
                { valErr: translateString("contracts.tac.validation.summaryIsRequired"), valFn: validators.isRequired },
              ],
            })
          }
          onSubmit={onSaveComment}
        >
          {(context) => (
            <Form>
              <CommentContainer>
                <Field
                  name="customerComment"
                  label={translateString("contracts.tac.customerComment")}
                  disabled={isLoading}
                  component={TextArea}
                />
                <div>
                  <Container $marginTop={2}>
                    <Field
                      name="planned"
                      label={translateString("contracts.tac.planned")}
                      disabled={isLoading}
                      onChange={() => context.setFieldValue("done", false)}
                      component={FormCheckbox}
                    />
                  </Container>
                  <Container $marginTop={2}>
                    <Field
                      name="done"
                      label={translateString("contracts.tac.done")}
                      disabled={isLoading}
                      onChange={() => context.setFieldValue("planned", false)}
                      component={FormCheckbox}
                    />
                  </Container>
                </div>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <LoadingSpinner size={spinnerSize.sm} disableText margin="none" />
                  ) : (
                    translateString("contracts.tac.saveChanges")
                  )}
                </Button>
              </CommentContainer>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Disclaimer>{translateString("contracts.tac.customerCommentReadOnly")}</Disclaimer>
          <ReadOnlyCommentContainer>{tac.customerComment || "-"}</ReadOnlyCommentContainer>
          <div>
            <Container $marginTop={2}>
              <Checkbox
                label={translateString("contracts.tac.planned")}
                checked={tac.status === "Planned"}
                disabled
                onChange={() => undefined}
              />
            </Container>
            <Container $marginTop={2}>
              <Checkbox
                label={translateString("contracts.tac.done")}
                checked={tac.status === "Done"}
                disabled
                onChange={() => undefined}
              />
            </Container>
          </div>
        </>
      )}
    </Container>
  );
};
