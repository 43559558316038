export enum RouteId {
  Index = "Index",
  Download = "Download",
  Requests = "Requests",
  CreateWarrantyClaim = "CreateWarrantyClaim",
  Documents = "Documents",
  Contact = "Contact",
  Contract = "Contract",
  TechnicalAuditCases = "TechnicalAuditCases",
  Equipments = "Equipments",
  EquipmentsRouteFallback = "EquipmentsRouteFallback",
  Installation = "Installation",
  InstallationInformation = "InstallationInformation",
  Users = "Users",
  Profile = "Profile",
  Reports = "Reports",
  ContactCM = "ContactCM",
  CreateFluidReport = "CreateFluidReport",
  RunningHours = "RunningHours",
  RunningHoursNew = "RunningHoursNew",
  FluidReportDraft = "FluidReportDraft",
  SpareParts = "SpareParts",
  VATStatements = "VATStatements",
  SparePartsItem = "SparePartsItem",
  SparePartsCatalogue = "SparePartsCatalogue",
  InstallationSelection = "InstallationSelection",
  Charts = "Charts",
  News = "News",
  ReleaseNotes = "ReleaseNotes",
  RequestQuotation = "RequestQuotation",
  OfflineRating = "OfflineRating",
  NewSFOCReport = "NewSFOCReport",
  SFOCHistory = "SFOCHistory",
  Invoices = "Invoices",
  NewRFQ = "NewRFQ",
  TrainingRegister = "TrainingRegister",
  Trainings = "Trainings",
  TrainingContact = "TrainingContact",
  ManagerDashboard = "ManagerDashboard",
  EnergyDocuments = "EnergyDocuments",
  MarineDocuments = "MarineDocuments",
  SFOCVesselDashboard = "SFOCVesselDashboard",
  SFOCFleetDashboard = "SFOCFleetDashboard",

  CommerceQuote = "SparePartsQuotationRequest",
  CommerceOrderSuccess = "CommerceOrderSuccess",
  CommerceQuoteSuccess = "CommerceQuoteSuccess",

  NewVATStatement = "NewVATStatement",

  CommerceOrder = "PlacePartsOrder",
  CommerceShoppingCart = "CommerceShoppingCart",
  Installations = "Installations",
  Loading = "Loading",
  SparePartPackages = "SparePartPackages",
}
