import * as React from "react";
import * as WUI from "@wartsila/ui-kit";

import { DateTimeFieldValue, Range } from "@wartsila/ui-kit/types/components/DateTimeField/types";
import { IWrapper, InputLabel } from "src/design-system/TextInput/TextInput";
import { inputTokens, spacer } from "src/design-system/Tokens/tokens";

import moment from "moment";
import styled from "styled-components";
import { dateMomentFormat } from "src/util/formatters";

const Wrapper = styled.div`
  ${(props: IWrapper) => (props.withMargin ? `margin-bottom: ${spacer(3)};` : "")}
  max-width: ${inputTokens.inputWidth};
`;

const RangeInput = styled(WUI.DateTimeField)`
  div[role="tooltip"] {
    z-index: 100;
  }

  div {
    box-sizing: border-box;
  }
`;

type RangeValue = WUI.DiscriminatedDateTimeFieldProps["Single"]["value"];

export interface IRangePickerProps {
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
  withMargin?: boolean;
  placeholder?: string;
  monthsShown?: number;
  endDate?: moment.Moment;
  startDate?: moment.Moment;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  onChange?: (range: Range<DateTimeFieldValue>) => Range<DateTimeFieldValue>;
}

export const RangePicker: React.FC<IRangePickerProps> = (props) => {
  return (
    <Wrapper withMargin={props.withMargin}>
      <InputLabel>{props.label}</InputLabel>
      <RangeInput
        invalid={props.invalid}
        formatter={([start, end]: [RangeValue, RangeValue]) => {
          return start && end ? [start.format(dateMomentFormat), end.format(dateMomentFormat)].join(" - ") : "";
        }}
        submit={WUI.DateTimeFieldSubmit.Apply}
        disabled={props.disabled}
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={[props.startDate || null, props.endDate || null]}
        monthsShown={props.monthsShown ? props.monthsShown : 2}
        minDate={props.minDate?.toDate()}
        maxDate={props.maxDate?.toDate()}
      />
    </Wrapper>
  );
};
