import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ITechnicalAuditCase, ITechnicalAuditDashboard } from "online-services-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { APIFetch, APIFetchStatus } from "src/APIFetch";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import { getQueryParamsFromFilters } from "src/services/tac/tac.utils";
import {
  ITechnicalAuditFiltersDispatchProps,
  ITechnicalAuditFiltersStateProps,
  ITechnicalAuditListStateProps,
  TechnicalAuditFilters,
  TechnicalAuditQueryParams,
} from "./tac.types";

const TECHNICAL_AUDIT_CASES = "technicalAuditCases";
const PRESELECTED_TECHNICAL_AUDIT_CASE = "preselectedTechnicalAuditCase";
const TECHNICAL_AUDIT_DASHBOARD = "technicalAuditDashboard";

const getTechnicalAuditCases = async (params: TechnicalAuditQueryParams = {}) => {
  return await new APIFetch<ITechnicalAuditCase[]>("contracts/technicalaudit/cases").getWithParams(params);
};

export const useTechnicalAuditCases = (filters: TechnicalAuditFilters = {}) => {
  const params = getQueryParamsFromFilters(filters);
  const paramsKey = new URLSearchParams(params).toString();
  const defaultLimit = Number(params.limit || 10);

  return useInfiniteQuery(
    [TECHNICAL_AUDIT_CASES, paramsKey],
    async ({ pageParam = 1 }) => {
      const prevPage = pageParam - 1;
      const firstPage = pageParam === 1;
      const offset = firstPage ? 0 : prevPage * defaultLimit;
      return getTechnicalAuditCases({ ...params, offset: offset.toString() });
    },
    {
      getNextPageParam: (_, allPages) => {
        const lastPage = allPages[allPages.length - 1];
        const isLastPage = lastPage.length < defaultLimit;

        return isLastPage ? undefined : allPages.length + 1;
      },
    }
  );
};

export const usePreselectedTechnicalAuditCase = (id?: string, enabled?: boolean) => {
  const params = getQueryParamsFromFilters({ id, limit: 1 });
  return useQuery(
    [PRESELECTED_TECHNICAL_AUDIT_CASE],
    async () => {
      return getTechnicalAuditCases(params);
    },
    { enabled }
  );
};

const patchTechnicalAuditCase = async (tac: ITechnicalAuditCase) => {
  return await new APIFetch<ITechnicalAuditCase>("contracts/technicalaudit/cases")
    .addHeaders({
      "x-wos-auto-assign": "FALSE",
    })
    .patch(tac, tac.id);
};

export const usePatchTechnicalAuditCase = ({ filters = {} }: { filters?: TechnicalAuditFilters }) => {
  const queryClient = useQueryClient();
  const paramsKey = new URLSearchParams(getQueryParamsFromFilters(filters)).toString();

  return useMutation(
    ["patchTechnicalAuditCases"],
    async ({ updatedTAC }: { updatedTAC: ITechnicalAuditCase }) => {
      return patchTechnicalAuditCase(updatedTAC);
    },
    {
      onMutate: async ({ updatedTAC }) => {
        await queryClient.cancelQueries({
          queryKey: [TECHNICAL_AUDIT_CASES, paramsKey],
        });
        await queryClient.cancelQueries({
          queryKey: [PRESELECTED_TECHNICAL_AUDIT_CASE],
        });
        const previousTACs = await queryClient.getQueryData([TECHNICAL_AUDIT_CASES, paramsKey]);
        const previousPreselectedTAC = await queryClient.getQueryData([PRESELECTED_TECHNICAL_AUDIT_CASE]);
        queryClient.setQueryData(
          [TECHNICAL_AUDIT_CASES, paramsKey],
          (prevData: InfiniteData<ITechnicalAuditCase[]>) => ({
            ...prevData,
            pages: prevData.pages.map((page) => page.map((tac) => (tac.id === updatedTAC.id ? updatedTAC : tac))),
          })
        );
        queryClient.setQueryData([PRESELECTED_TECHNICAL_AUDIT_CASE], (prevData: ITechnicalAuditCase[]) =>
          prevData ? prevData.map((tac) => (tac.id === updatedTAC.id ? updatedTAC : tac)) : undefined
        );
        return { previousTACs, previousPreselectedTAC };
      },
      onError: (_error, _variables, context: { previousTACs: unknown; previousPreselectedTAC: unknown }) => {
        if (context?.previousTACs) {
          queryClient.setQueryData([TECHNICAL_AUDIT_CASES, paramsKey], context.previousTACs);
        }
        if (context?.previousPreselectedTAC) {
          queryClient.setQueryData([PRESELECTED_TECHNICAL_AUDIT_CASE], context.previousPreselectedTAC);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [PRESELECTED_TECHNICAL_AUDIT_CASE],
        });
        queryClient.invalidateQueries({
          queryKey: [TECHNICAL_AUDIT_DASHBOARD],
        });
      },
    }
  );
};

const getTechnicalAuditDashboard = async () => {
  return await new APIFetch<ITechnicalAuditDashboard>("contracts/technicalaudit/dashboard").get();
};

export const useTechnicalAuditDashboard = () => {
  return useQuery([TECHNICAL_AUDIT_DASHBOARD], () => {
    return getTechnicalAuditDashboard();
  });
};

export const useTechnicalAuditListStateProps = () =>
  useSelector<IAppState, ITechnicalAuditListStateProps>((state) => ({
    userInfo: state.userInfo,
  }));

export const useTechnicalAuditFiltersStateProps = (): ITechnicalAuditFiltersStateProps =>
  useSelector<IAppState, ITechnicalAuditFiltersStateProps>((state) => ({
    installations: state.hierarchyInstallations.data || [],
    isReady: state.installations.status !== APIFetchStatus.Busy,
  }));

export const useTechnicalAuditFiltersDispatchProps = (): ITechnicalAuditFiltersDispatchProps => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      getInstallations: APIResourceState.hierarchyInstallations.get,
    },
    dispatch
  );
};
